import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { Audience, CampaignDetailsInterface, CampaignItemInfo, ChipsModel, DatepickerComponentConfig, MetricsModel, OrganisationResponse, PlatformTypeEnum } from '../../../models';
import { PrefillCampaignsService } from '../../../services/prefillCampaign.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { ChartComponent } from '../../../shared/chart/chart.component';
import { ChipsComponent } from '../../../shared/chips/chips.component';
import { DatepickerComponent } from '../../../shared/datepicker/datepicker.component';
import { MetricsComponent } from '../../../shared/tables/metrics/metrics.component';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';
import { CampaignDialogBoxComponent } from './campaign-dialog-box/campaign-dialog-box.component';

interface ChartDataWithTimestamps {
  timestamps: number[];
  chartData: Highcharts.SeriesOptionsType[];
  metrics: MetricsModel[]
}

@Component({
  selector: 'advocate-ui-campaign-table-info',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DatepickerComponent, ChipsComponent, ChartComponent, MetricsComponent, TranslateModule],
  templateUrl: './campaign-table-info.component.html',
  styleUrls: ['./campaign-table-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignTableInfoComponent implements OnInit {
  data$?: Observable<CampaignDetailsInterface>;
  id: number = 0;
  campaignData?: CampaignItemInfo;
  editCampaign: boolean = true;
  chartData$!: Observable<{ metrics: MetricsModel[] | undefined, chart: Highcharts.SeriesOptionsType[] | undefined }>;
  timestamps$!: Observable<number[] | undefined>;
  translate_NA = this.translateService.instant('GENERAL.NA');
  PlatformTypeEnum = PlatformTypeEnum;

  chips: ChipsModel[] = [
    {
      id: 'Total spend',
      label: 'Total spend',
      selected: true
    },
    {
      id: 'Impressions served',
      label: 'Impressions served',
      selected: false
    },
    {
      id: 'Average VCR',
      label: 'Average VCR',
      selected: false
    },
    {
      id: 'eCPM',
      label: 'eCPM',
      selected: false
    },
    {
      id: 'Clicks',
      label: 'Clicks',
      selected: false
    },
    {
      id: 'Average CTR',
      label: 'Average CTR',
      selected: false
    }
  ];

  fetchChartData(startDate?: string, endDate?: string): Observable<ChartDataWithTimestamps | undefined> {
    return this.utilityVMService.getOrganisationMetricsEffect(startDate, endDate, +this.id)
      .pipe(map(response => {

        const timestamps = response.metrics.simplified.timestamps;

        const totalspendData: number[] = [];
        const impressions: number[] = [];
        const vcr: number[] = [];
        const ecpm: number[] = [];
        const clicks: number[] = [];
        const ctr: number[] = [];

        response?.metrics.full.forEach(data => {
          totalspendData.push(data.true_cost);
          impressions.push(data.impressions);
          vcr.push(data.vcr);
          ecpm.push(data.ecpm);
          clicks.push(data.clicks);
          ctr.push(data.ctr);
        });

        const chartData: Highcharts.SeriesOptionsType[] = [
          {
            name: 'Total spend',
            data: totalspendData,
            type: 'line',
          },
          {
            name: 'Impressions served',
            data: impressions,
            type: 'line',
          },
          {
            name: 'Average VCR',
            data: vcr,
            type: 'line',
          },
          {
            name: 'eCPM',
            data: ecpm,
            type: 'line',
          },
          {
            name: 'Clicks',
            data: clicks,
            type: 'line',
          },
          {
            name: 'Average CTR',
            data: ctr,
            type: 'line',
          },
        ];

        return { timestamps, chartData, metrics: response.metrics.full };
      }));
  }

  myFormControl = new FormControl(this.chips[0]);

  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute,
    private utilityVMService: UtilityViewModelService, private prefillCampaignsService: PrefillCampaignsService,
    private errorService: ErrorHandlingViewModelService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.editCampaign = this.route.snapshot.queryParams['type'] === 'view' ? false : true;
    this.id = this.route.snapshot.params['id'];
    this.timestamps$ = this.fetchChartData().pipe(map(data => data?.timestamps));
    this.chartData$ = this.fetchChartData().pipe(
      map((data: ChartDataWithTimestamps | undefined) => ({
        chart: data?.chartData.filter(f => f.name === this.myFormControl.value?.label),
        metrics: data && data?.metrics.length > 0 ? data?.metrics : undefined
      })));

    this.data$ = combineLatest([
      this.utilityVMService.getCampaignDataEffect(+this.id),
      this.utilityVMService.getOrganisationDataEffect()
    ]).pipe(
      switchMap(([campaignData, organisationData]: [CampaignItemInfo, OrganisationResponse]) => {
        this.campaignData = campaignData;
        const audience: Audience | undefined = organisationData.organisation.audiences.find(a => a.id === campaignData.campaign.targeting.audience_id);
        const savedAudience: ChipsModel[] = [{
          id: 'saved_audience',
          label: audience?.name ?? this.translate_NA,
          selected: false
        }];
        const advancedTargeting: ChipsModel[] = [{
          id: 'premium_non_skippable_video',
          label: campaignData.campaign.targeting.has_premium_non_skippable_video ? 'Premium non skippable video' : this.translate_NA,
          selected: false
        },
        {
          id: 'premium_in_stream_video',
          label: campaignData.campaign.targeting.has_premium_in_stream_video ? 'Premium in stream video' : this.translate_NA,
          selected: false
        },
        {
          id: 'premium_placements',
          label: campaignData.campaign.targeting.has_premium_placements ? 'Premium placements' : this.translate_NA,
          selected: false
        },
        {
          id: 'premium_mobile_apps_only',
          label: campaignData.campaign.targeting.has_premium_mobile_apps_only ? 'Premium mobile apps only' : this.translate_NA,
          selected: false
        }].filter(f => f.label !== this.translate_NA);

        const demographicCountries = campaignData?.campaign.targeting.demographic_countries?.map(loc => ({ id: loc, name: loc }));

        const contracts$ = campaignData.campaign.targeting.contract_ids && campaignData.campaign.targeting.contract_ids?.length > 0
          ? this.utilityVMService.getContractsEffect(demographicCountries).pipe(map(item => item.all))
          : of([]);

        const congressionalDistricts$ = campaignData.campaign.targeting.congressional_district_ids && campaignData.campaign.targeting.congressional_district_ids?.length > 0
          ? this.utilityVMService.getCongressionalDistrictDataEffect().pipe(map(item => item?.country))
          : of([]);

        const geofences$ = campaignData.campaign.targeting.geofence_ids && campaignData.campaign.targeting.geofence_ids?.length > 0
          ? this.utilityVMService.getCampaignGeofenceDataEffect(demographicCountries).pipe(map(item => item.all))
          : of([]);

        const geofencingFile$ = campaignData.campaign.targeting.geofencing_file_id
          ? this.utilityVMService.getAnCreativeEffect(campaignData.campaign.targeting.geofencing_file_id).pipe(
            map(blob => {
              const url = window.URL.createObjectURL(blob!);
              return { url, filename: 'locations.csv' }; // Set your desired filename
            })
          )
          : campaignData.campaign.targeting.proximity_targets && campaignData.campaign.targeting.proximity_targets.length > 0
            ? of({
              url: window.URL.createObjectURL(
                this.prefillCampaignsService.generateCSV(campaignData.campaign.targeting.proximity_targets)
              ),
              filename: 'locations.csv'
            })
            : of(null);

        return combineLatest([contracts$, geofences$, geofencingFile$, congressionalDistricts$]).pipe(
          map(([contracts, geofences, geofenceFile, districts]) => {
            // Filter contracts based on target IDs
            const filteredContracts = contracts.filter(contract =>
              campaignData.campaign.targeting.contract_ids?.includes(contract.id)
            );

            // Filter geofences based on target IDs
            const filteredGeofences = geofences.filter(geofence =>
              campaignData.campaign.targeting.geofence_ids?.includes(geofence.id)
            );

            const filteredCongressionalDistricts = districts?.filter(district => campaignData.campaign.targeting.congressional_district_ids?.includes(district.id));

            return {
              saved_audience: savedAudience,
              advance_targeting: advancedTargeting.length > 0
                ? advancedTargeting
                : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              locations: campaignData?.campaign.targeting.locations?.length > 0 ? campaignData?.campaign.targeting.locations?.map(l => ({
                id: `${l.id}`,
                label: l.name,
                selected: false
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              zip_codes: campaignData?.campaign.targeting.zip_codes?.length > 0 ? campaignData?.campaign.targeting.zip_codes?.map(z => ({
                id: z,
                label: z,
                selected: false
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              advertiser_name: [{
                id: 'advertiser_name',
                label: this.formatValue(campaignData?.campaign.advertiser?.name),
                selected: false,
              }],
              name: [{
                id: 'name',
                label: this.formatValue(campaignData?.campaign.name),
                selected: false,
              }],
              objective: [{
                id: 'objective',
                label: this.formatValue(campaignData?.campaign.objective_label),
                selected: false,
              }],
              pacing: [{
                id: 'pacing',
                label: this.formatValue(campaignData?.campaign.budget_distribution_label),
                selected: false,
              }],
              genders: campaignData.campaign.targeting.genders?.length > 0 ? campaignData.campaign.targeting.genders?.map((gender: string) => ({
                id: gender,
                label: gender,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              age_groups: campaignData?.campaign.targeting.age_groups?.length > 0 ? campaignData?.campaign.targeting.age_groups?.map((age: string) => ({
                id: age,
                label: age,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              education: campaignData?.campaign.targeting.education?.length > 0 ? campaignData?.campaign.targeting.education?.map((ed: string) => ({
                id: ed,
                label: ed,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              running_days: campaignData?.campaign.running_days?.length > 0 ? campaignData?.campaign.running_days?.map((rd: string) => ({
                id: rd,
                label: rd,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              budget: [
                { id: 'daily_budget', label: (this.formatValue(campaignData?.campaign.daily_budget)) + ' - Daily' },
                { id: 'lifetime_budget', label: (this.formatValue(campaignData?.campaign.lifetime_budget)) + ' - Lifetime' }
              ],
              start_date_time: [
                {
                  id: 'start_date_time',
                  label: (this.formatValue(campaignData?.campaign.start_date)) + ' ' + (this.formatValue(campaignData?.campaign.start_time)),
                }
              ],
              end_date_time: [
                {
                  id: 'end_date_time',
                  label: (this.formatValue(campaignData?.campaign.end_date)) + ' ' + (this.formatValue(campaignData?.campaign.end_time)),
                }
              ],
              running_time_from: [
                { id: 'running_time_from', label: this.formatValue(campaignData?.campaign.running_time_from) }
              ],
              running_time_to: [
                { id: 'running_time_to', label: this.formatValue(campaignData?.campaign.running_time_to) }
              ],
              whitelisted_keywords: campaignData?.campaign.targeting.whitelisted_keywords?.length > 0 ? campaignData?.campaign.targeting.whitelisted_keywords?.map((ed: string) => ({
                id: ed,
                label: ed,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              blacklisted_keywords: campaignData?.campaign.targeting.blacklisted_keywords?.length > 0 ? campaignData?.campaign.targeting.blacklisted_keywords?.map((ed: string) => ({
                id: ed,
                label: ed,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              whitelisted_domains: campaignData?.campaign.targeting.whitelisted_domains?.length > 0 ? campaignData?.campaign.targeting.whitelisted_domains?.map((ed: string) => ({
                id: ed,
                label: ed,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              blacklisted_domains: campaignData?.campaign.targeting.blacklisted_domains?.length > 0 ? campaignData?.campaign.targeting.blacklisted_domains?.map((ed: string) => ({
                id: ed,
                label: ed,
                selected: false,
              })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              contracts: filteredContracts.length > 0 ? filteredContracts.map(obj => ({ id: obj.id, label: obj.name, selected: false })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              predefined_geofences: filteredGeofences.length > 0 ? filteredGeofences.map(obj => ({ id: obj.id, label: obj.name, selected: false })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
              geofences: geofenceFile,
              congressionalDistricts: filteredCongressionalDistricts && filteredCongressionalDistricts.length > 0 ? filteredCongressionalDistricts.map(obj => ({ id: obj.id, label: obj.name, selected: false })) : [{ id: this.translate_NA, label: this.translate_NA, selected: false }],
            };
          })
        );
      })
    );
  }

  formatValue(value: string | number | undefined | null) {
    if (value === "" || value === undefined || value === null) {
      return this.translate_NA;
    }
    return value;
  }


  navigateToHome() {
    this.router.navigate(['/home']);
  }

  changeChipSelection(chip: ChipsModel): void {
    this.chartData$ = this.fetchChartData().pipe(
      map(data => ({
        chart: chip.id === 'All' ? data?.chartData : data!.chartData.filter(f => f.name === chip.id),
        metrics: data?.metrics
      }))
    );

    const index: number = this.chips.findIndex(c => c.id === chip.id);
    if (index !== -1) {
      this.chips = this.chips.map((c: ChipsModel, i: number) => ({
        ...c,
        selected: i === index ? !c.selected : false
      }));
    }
  }

  openReportDialog(): void {
    this.dialog.open(CampaignDialogBoxComponent, {
      width: '65rem',
      height: '39rem',
      data: { id: this.id }
    });
  }

  onEditCampaign(): void {
    this.utilityVMService.initCampaignForecastData();
    this.errorService.searchKeywordErrorSignal.set(null);
    !this.campaignData?.campaign.type_id && this.campaignData?.campaign.platform_label === PlatformTypeEnum.Programmatic ? this.router.navigate(['/home/programmatic/creative-options'], { state: { data: this.campaignData } }) : this.prefillCampaignsService.prefill(this.campaignData);
  }

  searchDatePicker(startDate: DatepickerComponentConfig, endDate: DatepickerComponentConfig) {
    const updatedStartDate = moment(startDate.control.value).format('YYYY-MM-DD');
    const updatedEndDate = moment(endDate.control.value).format('YYYY-MM-DD');
    this.chartData$ = this.fetchChartData(updatedStartDate, updatedEndDate).pipe(map(data => ({
      chart: data?.chartData,
      metrics: data?.metrics
    })));
    this.timestamps$ = this.fetchChartData(updatedStartDate, updatedEndDate).pipe(map(data => data?.timestamps));
  }
}
