import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { PaymentData } from "../models";

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    private apiUrl = environment.apiKey;; // Replace with your API URL
    constructor(private http: HttpClient) { }

    getPaymentLogs(orgId: number, page?: number, per_page?: number): Observable<PaymentData> {
        const url = `${this.apiUrl}/organisations/${orgId}/payments`;
        const params: any = {};
        if (page) params.page = page;
        if (per_page) params.per_page = per_page;
        return this.http.get<PaymentData>(url, { params });
    }

    postPayment(orgId: number, amount: number, success_url?: string, cancel_url?: string): Observable<{ redirect_to: string }> {
        const url = `${this.apiUrl}/organisations/${orgId}/payments`;

        const body: { amount: number; redirect: boolean; success_url?: string; cancel_url?: string; } = { amount, redirect: false };

        if (success_url) body.success_url = success_url;
        if (cancel_url) body.cancel_url = cancel_url;

        return this.http.post<{ redirect_to: string }>(url, body);
    }
}