import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BehaviorSubject, Observable, catchError, combineLatest, forkJoin, map, mergeMap, of,
    switchMap, take, tap, throwError
} from 'rxjs';
import { environment } from '../../environments/environment';
import {
    AdvertiserItemModel, AdvertiserModel, AdvertisersDataModel, AdvocateCurrencyOptions,
    AdvocateOption, Campaign, CampaignForecastResponse, CampaignGetReponseData, CampaignGroupsTableInterface,
    CampaignItemInfo, CampaignNoMenClature, CampaignNoMenClatureItem, CampaignPostResponseData, CampaignSteps,
    CampaignsResponse, ChangePasswordInterface, Creative, CreativeTypeEnum, CreativesResponse,
    FileScopeTypeEnum, FilesResponse, GeoFenceListResponse, KeywordResponse, LoctionsResponse, NoMenClaturesMetaDataModel,
    NoMenClaturesMetaDataStateModel, PaymentData, PlatformTypeEnum, ProximityTarget, ProximityTargetResponse,
    Step0, TabOptions, UserData, UserInfo, ValidateZipCodesResponse, ZipCodeData, campaignActionResponse
} from '../../models';
import { ContractsResponse } from '../../models/contracts.model';
import { FAQs } from '../../models/faqs.model';
import { EnquiriesResponse, NotificationItemModel, NotificationResponse } from '../../models/notification.model';
import { OrganisationMetricsResponse, OrganisationResponse } from '../../models/organisation.model';
import { PoliticalDataBody, PoliticalDataResponse, PostPoliticalDataResponse } from '../../models/political-data.model';
import { AdvertiserService } from '../../services/advertiser.service';
import { CampaignsService } from '../../services/campaigns.service';
import { FormService } from '../../services/form.service';
import { InternalService } from '../../services/internal.service';
import { NotificationService } from '../../services/notification.service';
import { OrganisationService } from '../../services/organisation.service';
import { PaymentService } from '../../services/payment.service';
import { PoliticalDataService } from '../../services/political-data.service';
import { UtilityService } from '../../services/utility.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UtilityViewModelService {
    private campaignForecast$ = new BehaviorSubject<CampaignForecastResponse | null>(null);
    constructor(private utilityService: UtilityService, private authService: AuthService,
        private advertiserService: AdvertiserService, private campaignService: CampaignsService,
        private formService: FormService, private notificationService: NotificationService,
        private organisationService: OrganisationService, private internalService: InternalService,
        private paymentService: PaymentService, private politicalDataService: PoliticalDataService) { }

    getCampaignNoMenClatureEffect(): Observable<CampaignNoMenClature | null> {
        return this.utilityService.getNomenClaturesState().pipe(
            switchMap((data: CampaignNoMenClature | null) => {
                return data ? of(data) : this.utilityService.getNomenClatures();
            })
        );
    }

    getCurrencyEffect(): Observable<AdvocateCurrencyOptions> {
        return this.getLoginInfoEffect().pipe(
            map((data: UserData | null): AdvocateCurrencyOptions => {
                const label = data?.user.organisation.currency_label ?? 'USD';
                const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: label });
                const currencySymbolPart = formatter.formatToParts(0).find(part => part.type === 'currency');
                return { id: label, name: currencySymbolPart ? currencySymbolPart.value : '$' };
            })
        );
    }


    createAdvertiserEffect(
        advertiserDetail: AdvertiserModel
    ): Observable<AdvertiserItemModel> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.advertiserService.createAdvertiser(
                    _data.user.organisation.id,
                    advertiserDetail
                );
            })
        );
    }

    getLoginInfoEffect(): Observable<UserData | null> {
        return this.authService.getLoginInfoState().pipe(
            switchMap((data: UserData | null) => {
                return data
                    ? of(data)
                    : this.authService.loginInfo();
            }),
            catchError(error => {
                console.error('Error 12', error);
                return of(null)
            })
        );
    }

    getAdvertiserEffect(page?: number, per_page?: number): Observable<AdvertisersDataModel> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.advertiserService.getAdvertiserData(_data.user.organisation.id, page, per_page)
            })
        );
    }

    getAllCampaignsEffect(includeArchiveFlag: number, page?: number, perpage?: number, advId?: number, typeId?: number, cname?: string, status_id?: number): Observable<{ campaignResponse: CampaignsResponse, transformedData: CampaignGroupsTableInterface[] }> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.campaignService.getAllCampaigns(_data.user.organisation.id, includeArchiveFlag, page, perpage, advId, typeId, cname, status_id).pipe(
                    map((campaignResponse: CampaignsResponse) => {
                        const transformedData = campaignResponse.campaigns.items.map((c: Campaign) => {
                            return {
                                id: c.id ?? 'N/A',
                                name: c.name ?? 'N/A',
                                type: c.platform_label,
                                impressions: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.impressions : c.lifetime_data?.impressions ?? 0,
                                cpm: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.cpm : c.lifetime_data?.cpm ?? 0,
                                cpc: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.cpc : c.lifetime_data?.cpc ?? 0,
                                vcr: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.vcr : c.lifetime_data?.vcr ?? 0,
                                ctr: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.ctr : c.lifetime_data?.ctr ?? 0,
                                spend: Array.isArray(c.lifetime_data) ? c.lifetime_data[0]?.spend : c.lifetime_data?.spend ?? 0,
                                flight_start_date: c.start_date ?? 'N/A',
                                flight_end_date: c.end_date ?? 'N/A',
                                lifetime_budget: c.lifetime_budget ?? 0,
                                daily_budget: c.daily_budget ?? 0,
                                edit: '',
                                status_id: c.status_id,
                                status_label: c.status_label,
                                campaignType: c.type_label,
                                can_archive: c.flags.can_archive,
                                can_unarchive: c.flags.can_unarchive,
                                can_pause: c.flags.can_pause,
                                is_archived: c.flags.is_archived,
                                can_resume: c.flags.can_resume,
                                organisation_projected_balance_after_publish: c.misc.organisation_projected_balance_after_publish,
                                campaign_projected_spend: c.misc.campaign_projected_spend,
                                top_up_suggestion: c.misc.top_up_suggestion,
                            };
                        });

                        return { campaignResponse, transformedData };
                    })
                );
            })
        );
    }

    createAnCampaignEffect(selectedTabName: PlatformTypeEnum, selectedCreative?: CreativeTypeEnum): Observable<CampaignPostResponseData> {
        return combineLatest([this.getLoginInfoEffect(), this.getCampaignNoMenClatureEffect()]).pipe(
            switchMap(([userData, noMenClatureData]) => {
                const _data = userData as UserData;
                const _noMenClatureData = noMenClatureData as CampaignNoMenClature;
                const selectedPlatformId = (_noMenClatureData.campaign_platform_id.find(d => d.name === selectedTabName) as CampaignNoMenClatureItem)?.id;
                const selectedCreativeId = (_noMenClatureData.creative_type_id.find(d => d.name === selectedCreative) as CampaignNoMenClatureItem)?.id;
                const tabOption: TabOptions = {
                    tabName: selectedTabName,
                    id: selectedPlatformId
                }
                this.formService.form.get('platformId')?.setValue(selectedPlatformId);
                this.internalService.selectedTabSignal.set(tabOption);
                const body: { step0: Step0 } = {
                    step0: {
                        platform_id: selectedPlatformId,
                        type_id: selectedCreativeId
                    }
                }
                return this.campaignService.createCampaign(_data.user.organisation.id, body)
            })
        )
    }

    updateAnCampaignStepEffect(campaignStep: CampaignSteps): Observable<any> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData) => {
                const _data = userData as UserData;
                return this.campaignService.updateCampaignStep(_data.user.organisation.id, this.campaignService.campaignIdSignal()!, campaignStep);
            })
        )
    }

    getCampaignStepsEffect(campaignId: number): Observable<CampaignGetReponseData> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.campaignService.getCampaignSteps(_data.user.organisation.id, campaignId);
            })
        )
    }

    uploadCreativeFileEffect(formData: FormData, fileScopeId: FileScopeTypeEnum): Observable<FilesResponse | HttpErrorResponse> {
        return combineLatest([this.getLoginInfoEffect(), this.getCampaignNoMenClatureEffect()]).pipe(
            switchMap(([userData, noMenClatureData]) => {
                const _userData = userData as UserData;
                const _noMenClatureData = noMenClatureData as CampaignNoMenClature;
                const scope_id = (_noMenClatureData.file_scope_id.find(d => d.name === fileScopeId) as CampaignNoMenClatureItem).id;

                if (fileScopeId === FileScopeTypeEnum.Campaign) {
                    const creativeTypeId = (_noMenClatureData.creative_type_id.find(d => d.name === this.formService.programmaticCreativeFormGroup.get('creativeType')?.value?.name) as CampaignNoMenClatureItem)?.id;
                    formData.append('creative_type_id', creativeTypeId?.toString());
                }

                if (this.campaignService.campaignIdSignal()) formData.append('campaign_id', (this.campaignService.campaignIdSignal() as number).toString());
                formData.append('scope_id', scope_id.toString());

                return this.campaignService.uploadCreativeFile(_userData.user.organisation.id, formData);
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('Error -->', error);
                if (error.status === 422) {
                    const filename = Object.keys(error.error.errors)[0];
                    const errorMessage = error.error.errors[filename];
                    return throwError(() => `${filename}: ${errorMessage}`);
                }
                return throwError(() => error);
            })
        )
    }

    getAnCreativeEffect(fileId: number): Observable<Blob | null> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData) => {
                const _data = userData as UserData;
                return this.campaignService.getCreativeFile(_data.user.organisation.id, fileId)

            }),
            catchError(error => {
                console.error('Error', error);
                return of(null)
            })
        )
    }

    getAnCreativeURLEffect(fileId: number): Observable<string> {
        const apiUrl = environment.apiKey;
        return this.getLoginInfoEffect().pipe(
            map((userData) => {
                const _data = userData as UserData;
                return `${apiUrl}/organisations/${_data.user.organisation.id}/files/${fileId}`;
            }),
            catchError(error => {
                console.error('Error', error);
                return of('');
            })
        );
    }


    getNoMenClaturesPlatformMetaEffect(): Observable<NoMenClaturesMetaDataModel | null> {
        return this.utilityService.getNomenClaturesPlatformMetaState().pipe(
            switchMap((data: NoMenClaturesMetaDataStateModel | null) => {
                return data && data.platform_id === this.internalService.selectedTabSignal()?.id
                    ? of(data.values)
                    : this.utilityService.getNomenClaturesPlatformMeta(this.internalService.selectedTabSignal()?.id as number)
            })
        );
    }

    getLocationsEffect(searchTerm: string): Observable<LoctionsResponse | null> {
        const countriesArray: AdvocateOption[] = this.formService.targetingFormGroup.get('demographicCountries')?.value;
        const countries: string = countriesArray.map((item: AdvocateOption) => item.id).join(',');
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                return this.utilityService.getLocations(_data.user.organisation.id, this.campaignService.campaignIdSignal()!, searchTerm, [countries]);
            }),
            catchError(error => {
                console.error('Error', error);
                return of(null)
            })
        )
    }

    changePasswordEffect(body: ChangePasswordInterface | { two_factor_code: string }): Observable<UserData> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.authService.changePassword(
                    _data.user.organisation.id,
                    _data.user.id,
                    body
                );
            })
        );
    }

    getNotificationsEffect(status_id?: number, perPage?: number): Observable<NotificationResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.notificationService.getNotifications(
                    _data.user.organisation.id,
                    _data.user.id,
                    status_id,
                    perPage,
                );
            })
        );
    }

    editAdvertiserEffect(advId: number, advertiserDetail: AdvertiserModel): Observable<AdvertiserItemModel> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.advertiserService.editAdvertiser(_data.user.organisation.id, advId, advertiserDetail);
            })
        );
    }

    notificationActionEffect(notificationId: number): Observable<NotificationItemModel> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.notificationService.postNotificationActions(
                    _data.user.organisation.id,
                    _data.user.id,
                    notificationId
                );
            })
        );
    }

    getOrganisationDataEffect(): Observable<OrganisationResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.organisationService.getOrganisationData(_data.user.organisation.id);
            })
        );
    }

    getOrganisationMetricsEffect(startDate?: string | undefined, endDate?: string | undefined, id?: number | undefined): Observable<OrganisationMetricsResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.organisationService.getOrganisationMetrics(_data.user.organisation.id, startDate, endDate, id);
            })
        );
    }

    getZipCodesEffect(countriesArray: AdvocateOption[]): Observable<ZipCodeData> {
        return this.utilityService.getZipCodes(countriesArray);
    }

    getCampaignDataEffect(id?: number): Observable<CampaignItemInfo> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                const campaignId = id ?? this.campaignService.campaignIdSignal();
                return this.campaignService.getCampaignData(_data.user.organisation.id, campaignId!);
            })
        )
    }

    actionCampaignEffect(action: string): Observable<campaignActionResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.actionCampaignEffect(_data.user.organisation.id, _campaignId, action);
            })
        )
    }

    duplicateActionCampaignEffect(campaignId: number, action: string, platformId?: number): Observable<CampaignItemInfo> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.campaignService.duplicateCampaign(_data.user.organisation.id, campaignId, action, platformId);
            })
        )
    }

    removeCreativeEffect(creativeId: number): Observable<{ creative: Creative }> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.creativeAction(_data.user.organisation.id, _campaignId, creativeId, 'remove');
            })
        )
    }

    createProximityEffect(): Observable<ProximityTargetResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                const body: ProximityTarget = {
                    radius: this.formService.proximityFormGroup.get('radius')?.value,
                    radius_unit_id: this.formService.proximityFormGroup.get('radius_unit_id')?.value.id,
                    latitude: this.formService.proximityFormGroup.get('latitude')?.value,
                    longitude: this.formService.proximityFormGroup.get('longitude')?.value
                }
                return this.campaignService.createProximity(_data.user.organisation.id, _campaignId, body).pipe(
                    tap((t: ProximityTargetResponse) => {
                        this.formService.proximityFormGroup.get('id')?.setValue(t.proximity_target.id);
                        this.formService.proximityFormGroup.get('latitude')?.setValue(t.proximity_target.latitude);
                        this.formService.proximityFormGroup.get('longitude')?.setValue(t.proximity_target.longitude);
                        this.formService.proximityFormGroup.get('radius')?.setValue(t.proximity_target.radius);
                        this.formService.proximityFormGroup.get('radius_unit_id')?.setValue(t.proximity_target.radius_unit_id);
                        this.formService.proximityFormGroup.get('active')?.setValue(t.proximity_target.active);
                        this.formService.proximityFormGroup.get('address')?.setValue(t.proximity_target.address ?? '');
                        this.formService.proximityFormGroup.get('radius_unit_label')?.setValue(t.proximity_target.radius_unit_label);

                        this.formService.proximityFormArray.push(this.formService.cloneFormGroup(this.formService.proximityFormGroup));
                        this.formService.proximityFormGroup.reset();
                    }))
            })
        )
    }

    addKeywordsEffect(): Observable<KeywordResponse> {
        return combineLatest([this.getLoginInfoEffect(), this.getCampaignNoMenClatureEffect()]).pipe(
            switchMap(([userData, nomenclatureData]) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                const _nomenclatureData = nomenclatureData as CampaignNoMenClature;

                const typeId: number = _nomenclatureData.keyword_type_id.find(f => f.name === 'Targeted')?.id as number;
                const targetingBody = {
                    type_id: typeId,
                    keyword: this.formService.targetingKeywordFormGroup.get('keyword')?.value,
                    match_type_id: this.formService.targetingKeywordFormGroup.get('match_type_label')?.value?.id
                };

                return this.utilityService.addKeywords(_data.user.organisation.id, _campaignId, targetingBody)
            })
        )
    }

    addNegativeKeywordsEffect(): Observable<KeywordResponse> {
        return combineLatest([this.getLoginInfoEffect(), this.getCampaignNoMenClatureEffect()]).pipe(
            switchMap(([userData, nomenclatureData]) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                const _nomenclatureData = nomenclatureData as CampaignNoMenClature;

                const typeId: number = _nomenclatureData.keyword_type_id.find(f => f.name === 'Negative')?.id as number;

                const negativeBody = {
                    type_id: typeId,
                    keyword: this.formService.negativeKeywordFormGroup.get('keyword')?.value,
                    match_type_id: this.formService.negativeKeywordFormGroup.get('match_type_label')?.value?.id
                };
                return this.utilityService.addKeywords(_data.user.organisation.id, _campaignId, negativeBody)
            })
        )
    }

    getCreativesOfCampaignEffect(): Observable<Creative[]> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.campaignService.getCreativesOfCampaign(_data.user.organisation.id, this.campaignService.campaignIdSignal()!).pipe(
                    mergeMap((creatives: CreativesResponse) => {
                        const creativeObservables: Observable<Creative>[] = creatives.creatives.items.map((creative: Creative) => {
                            if (creative.file_id !== null && creative.file_id !== undefined) {
                                return this.campaignService.getCreativeFile(_data.user.organisation.id, creative.file_id).pipe(
                                    map((blobData: Blob | null) => {
                                        // Merge the blobData into the original creative object
                                        return { ...creative, blobData };
                                    })
                                );
                            } else {
                                // If fileID is null or undefined, append null without making the request
                                return of({ ...creative, blobData: null });
                            }
                        });

                        // Use forkJoin to parallelize the requests and wait for all to complete
                        return forkJoin(creativeObservables);
                    })
                );
            })
        );
    }


    actionCreativeEffect(creativeId: number, action: string): Observable<{ creative: Creative }> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.creativeAction(_data.user.organisation.id, _campaignId, creativeId, action);
            })
        )
    }

    postEnquiriesEffect(body: EnquiriesResponse): Observable<{ ok: boolean }> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.notificationService.postEnquiries(_data.user.organisation.id, _data.user.id, body);
            })
        )
    }

    getCampaignForecastDataEffect(): Observable<CampaignForecastResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.getCampaignsForecast(_data.user.organisation.id, _campaignId);
            })
        )
    }

    getPaymentLogEffect(page?: number, per_page?: number): Observable<PaymentData> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.paymentService.getPaymentLogs(_data.user.organisation.id, page, per_page);
            })
        );
    }

    postPaymentEffect(amount: number, success_url?: string, cancel_url?: string): Observable<{ redirect_to: string }> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.paymentService.postPayment(_data.user.organisation.id, amount, success_url, cancel_url);
            })
        );
    }

    /**
     * Call this method each time user hits on edit campaign. Also unsubsrcibe it soon after one take. 
     */
    initCampaignForecastData(): void {
        this.getCampaignForecastDataEffect().pipe(
            take(1),
            tap((forecast: CampaignForecastResponse) => this.campaignForecast$.next(forecast)),
            catchError((error: HttpErrorResponse) => {
                console.error('Error fetching campaign forecast', error);
                return of(null);
            })
        ).subscribe();
    }

    getCampaignForecast(): Observable<CampaignForecastResponse | null> {
        return this.campaignForecast$.asObservable();
    }

    getFAQs(): Observable<FAQs> {
        return this.utilityService.getFaqs();
    }

    getContractsEffect(countriesArray: AdvocateOption[]): Observable<ContractsResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.utilityService.getContracts(_data.user.organisation.id, _campaignId, countriesArray);
            })
        )
    }

    featureFlagOnDevAlone(): boolean {
        const fullUrl = window.location.href;
        return fullUrl.includes('app-dev.advocate-digital.com') || fullUrl.includes('localhost:4200');
    }

    getPoliticalDataEffect(page?: number, per_page?: number): Observable<PoliticalDataResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.politicalDataService.getPoliticalData(_data.user.organisation.id, page, per_page);
            })
        )
    }

    postPoliticalDataEffect(body: PoliticalDataBody): Observable<PostPoliticalDataResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.politicalDataService.postPoliticalData(_data.user.organisation.id, body);
            })
        )
    }

    getPoliticalDataByIdEffect(politicalId: number): Observable<PoliticalDataResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.politicalDataService.getPoliticalDataById(_data.user.organisation.id, politicalId);
            })
        )
    }

    patchPoliticalDataByIdEffect(politicalId: number, body: PoliticalDataBody): Observable<PostPoliticalDataResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((data: UserData | null) => {
                const _data = data as UserData;
                return this.politicalDataService.patchPoliticalDataById(_data.user.organisation.id, politicalId, body);
            })
        )
    }

    uploadOrganisationVerificationFileEffect(formData: FormData): Observable<FilesResponse | HttpErrorResponse> {
        return combineLatest([this.getLoginInfoEffect(), this.getCampaignNoMenClatureEffect()]).pipe(
            switchMap(([userData, noMenClatureData]) => {
                const _userData = userData as UserData;
                const _noMenClatureData = noMenClatureData as CampaignNoMenClature;
                const scope_id = (_noMenClatureData.file_scope_id.find(d => d.name.toLowerCase() === 'political') as CampaignNoMenClatureItem).id;
                formData.append('scope_id', scope_id.toString());

                return this.campaignService.uploadCreativeFile(_userData.user.organisation.id, formData);
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('Error -->', error);
                if (error.status === 422) {
                    const filename = Object.keys(error.error.errors)[0];
                    const errorMessage = error.error.errors[filename];
                    return throwError(() => `${filename}: ${errorMessage}`);
                }
                return throwError(() => error);
            })
        )
    }

    getCampaignGeofenceDataEffect(countriesArray: AdvocateOption[]): Observable<GeoFenceListResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.getCampaignsGeofence(_data.user.organisation.id, _campaignId, countriesArray);
            })
        )
    }

    getCampaignUrlsDataEffect(countriesArray: AdvocateOption[]): Observable<GeoFenceListResponse> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.getCampaignsUrls(_data.user.organisation.id, _campaignId, countriesArray);
            })
        )
    }

    setLanguageEffect(languageId: number): Observable<UserInfo> {
        const body = {
            language_id: languageId
        }
        return this.utilityService.setPreferedLanguage(body);
    }

    getCongressionalDistrictDataEffect(): Observable<GeoFenceListResponse | null> {
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.campaignService.getCongressionalDistrict(_data.user.organisation.id, _campaignId);
            }),
            catchError(error => {
                console.error('Error', error);
                return of(null)
            })
        )
    }

    validateZipCodesEffect(zipcodes: string[]): Observable<ValidateZipCodesResponse> {
        const body = {
            countries: this.formService.targetingFormGroup.get('postalCodeCountriesArray')?.value.map((country: AdvocateOption) => country.name) ?? [],
            zip_codes: zipcodes
        }
        return this.getLoginInfoEffect().pipe(
            switchMap((userData: UserData | null) => {
                const _data = userData as UserData;
                const _campaignId = this.campaignService.campaignIdSignal() as number;
                return this.utilityService.validateZipCodes(_data.user.organisation.id, _campaignId, body);
            })
        )
    }
}
