import { Injectable, OnDestroy, WritableSignal, signal } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { UtilityViewModelService } from '../app/view-model/utility-vm.service';
import { CampaignItemInfo, Creative, PlatformTypeEnum, ProximityTarget, TabOptions } from "../models";
import { CampaignsService } from './campaigns.service';
import { FormService } from "./form.service";
import { InternalService } from "./internal.service";

@Injectable({
    providedIn: 'root',
})

export class PrefillCampaignsService implements OnDestroy {
    private subs = new Subscription();
    public errorSignals: WritableSignal<string> = signal('');
    constructor(private formService: FormService, private fb: FormBuilder, private internalService: InternalService,
        private router: Router, private campaignService: CampaignsService, private uvmService: UtilityViewModelService) { }

    prefill(res: CampaignItemInfo | undefined) {
        this.formService.form.reset();
        this.formService.adsFormGroup.reset();
        this.formService.environmentsArray.clear();
        this.formService.languagesArray.clear();
        this.formService.locationsArray.clear();
        this.formService.demographicCountriesArray.clear();
        this.formService.adsCreativeArray.clear();
        this.formService.proximityFormArray.clear();
        this.formService.interestsArray.clear();
        this.formService.targetingFormArray.clear();
        this.formService.negativeFormArray.clear();
        this.formService.programmaticProximityFormArray.clear();
        this.formService.ageGroupsArray.clear();
        this.formService.genderGroupsArray.clear();
        this.formService.devicesGroupsArray.clear();
        this.formService.programmaticCreativesArray.clear();
        this.formService.youtubeCreativesArray.clear();
        this.formService.whiteListedKeywordsArray.clear();
        this.formService.blackListedKeywordsArray.clear();
        this.formService.whiteListedDomainsArray.clear();
        this.formService.blackListedDomainsArray.clear();
        this.formService.runningDaysArray.clear();
        this.formService.contractIdArray.clear();
        this.formService.geofenceListArray.clear();
        this.formService.headlinesFormArray.clear();
        this.formService.descriptionsFormArray.clear();
        this.formService.congressionalDistrictListArray.clear();
        this.formService.postalCodeCountriesArray.clear();

        if (res?.campaign.id) this.campaignService.campaignIdSignal.set(res?.campaign.id)

        const programmaticCreativeTabOption: TabOptions = {
            tabName: res?.campaign.type_label ?? '',
            id: res?.campaign.type_id,
            hovered: true,
            selected: true,
        }
        this.internalService.selectedCreativeTabSignal.set(programmaticCreativeTabOption);

        this.formService.campaignNameFormGroup.patchValue({
            selectedAdvertiser: res?.campaign?.advertiser_id,
            advertiserName: null,
            assignedDebit: 0,
            campaignName: res?.campaign.name ?? ''
        });

        this.formService.objectiveControl.setValue({
            tabName: res?.campaign?.objective_label ?? '',
            id: res?.campaign?.objective_id || 0,
            hovered: false,
            selected: true,
        });

        this.formService.budgetFormGroup.patchValue({
            startDate: res?.campaign.start_date || '',
            startTime: res?.campaign.start_time || '',
            startTimeZoneId: res?.campaign.start_timezone_id || 0,
            endTime: res?.campaign.end_time || '',
            endTimeZoneId: res?.campaign.end_timezone_id || 0,
            lifeTimeBudget: res?.campaign.lifetime_budget || 0,
            dailyBudget: res?.campaign.daily_budget || 1,
            maxBid: res?.campaign.max_bid || null,
            budgetDistributionId: res?.campaign.budget_distribution_id || '',
            bid: res?.campaign.bid || 0,
            runningTimeFrom: res?.campaign.running_time_from || '',
            runningTimeTo: res?.campaign.running_time_to || '',
            endDate: res?.campaign.end_date || '',
        });

        if (res?.campaign && res.campaign.running_days) this.prefillRunningDaysGroups(res.campaign.running_days);

        const creativesCampaign: Creative[] | undefined = res?.campaign.creatives;
        if (creativesCampaign !== undefined && creativesCampaign !== null && creativesCampaign.length > 0) {
            switch (res?.campaign.platform_label) {
                case PlatformTypeEnum.Programmatic:
                    creativesCampaign.forEach(item => {
                        this.formService.programmaticCreativeFormGroup.patchValue({
                            creativeType: { id: item?.type_id, name: item?.type_label },
                            fileId: item?.file_id || '',
                            file: '',
                            name: item?.name || '',
                            sponsoredBy: item?.sponsored_by || '',
                            landingPageUrl: item?.landing_url || '',
                            retargeting: item?.retargeting === false ? "no" : "yes",
                            headline: item?.headline || '',
                            id: item?.id || '',
                            statusId: item?.status_id || '',
                            statusLabel: item?.status_label || '',
                            text: item?.text || '',
                            typeId: item?.type_id || '',
                            typeLabel: item?.type_label || '',
                            callToAction: item?.call_to_action_text || '',
                        });
                        this.formService.programmaticCreativesArray.push(this.formService.cloneFormGroup(this.formService.programmaticCreativeFormGroup));
                        this.formService.programmaticCreativeFormGroup.reset();
                    });
                    break;

                case PlatformTypeEnum.YouTube:
                    creativesCampaign.forEach((creative: Creative) => {
                        this.formService.youtubeCreativeFormGroup.patchValue({
                            id: creative?.id,
                            type_id: creative?.type_id,
                            type_label: creative?.type_label,
                            name: creative.name || '',
                            sponsoredBy: creative.sponsored_by || '',
                            landingPageUrl: creative.landing_url || '',
                            displayUrl: creative.display_url || '',
                            videoUrl: creative.video_url || '',
                            headline: creative.headline || '',
                            text: creative.text || '',
                            statusId: creative.status_id || '',
                            statusLabel: creative.status_label || ''
                        });

                        this.formService.youtubeCreativesArray.push(this.formService.cloneFormGroup(this.formService.youtubeCreativeFormGroup));
                        this.formService.youtubeCreativeFormGroup.reset();
                    });
                    break;

                case PlatformTypeEnum.Search:
                    creativesCampaign.forEach((creative: Creative) => {
                        this.formService.adsFormGroup.patchValue({
                            headline_1: creative.headline_1,
                            headline_2: creative.headline_2,
                            headline_3: creative.headline_3,
                            description_1: creative.description_1,
                            description_2: creative.description_2,
                            landing_url: creative.landing_url,
                            id: creative.id,
                            type_id: creative.type_id,
                            type_label: creative.type_label,
                            status_id: creative.status_id,
                            status_label: creative.status_label
                        });

                        creative.additional_headlines?.forEach(item => {
                            this.formService.headlinesFormArray.push(this.fb.control(item));
                        })

                        creative.additional_descriptions?.forEach(item => {
                            this.formService.descriptionsFormArray.push(this.fb.control(item));
                        });

                        this.formService.adsCreativeArray.push(this.formService.cloneFormGroup(this.formService.adsFormGroup));
                        this.formService.adsFormGroup.reset();
                        this.formService.headlinesFormArray.clear();
                        this.formService.descriptionsFormArray.clear();
                    })
                    break;
            }
        }

        // Add Creative Type Details even if no creatives. Reason being user selected that while creating campaign Step 0
        if (res?.campaign.type_id && res.campaign.type_label) {
            this.formService.programmaticCreativeFormGroup.get('creativeType')?.setValue({
                name: res?.campaign.type_label,
                id: res?.campaign.type_id
            });
        }

        const targetingCampaign = res?.campaign.targeting;
        if (targetingCampaign !== null && targetingCampaign !== undefined) {
            this.formService.targetingFormGroup.patchValue({
                isPoliticalCampaign: targetingCampaign.is_political ? 'yes' : 'no',
                keywordLanguage: targetingCampaign.keyword_language || '',
                frequencyCap: targetingCampaign.frequency_cap,
                frequencyCapInterval: targetingCampaign.frequency_cap_interval,
                frequencyCapIntervalId: targetingCampaign.frequency_cap_interval_type_id,
                audience: targetingCampaign.audience_id,
                premiumNonSkippableVideo: targetingCampaign.has_premium_non_skippable_video,
                premiumInStreamVideo: targetingCampaign.has_premium_in_stream_video,
                premiumPlacements: targetingCampaign.has_premium_placements,
                premiumMobileAppsOnly: targetingCampaign.has_premium_mobile_apps_only,
            });

            if (targetingCampaign.genders) this.prefillGenderGroups(targetingCampaign.genders);
            if (targetingCampaign.age_groups) this.prefillAgeGroups(targetingCampaign.age_groups);
            if (targetingCampaign.devices) this.prefillDeviceGroups(targetingCampaign.devices);

            targetingCampaign.environments?.forEach(environment => {
                this.formService.utilityFormGroup.setValue({
                    id: environment,
                    name: environment
                })
                this.formService.environmentsArray.push(this.formService.cloneFormGroup(this.formService.utilityFormGroup));
                this.formService.utilityFormGroup.reset();
            });

            this.formService.targetingFormGroup.get('postalCodes')?.setValue(targetingCampaign.zip_codes?.join(','));

            targetingCampaign.languages?.forEach(language => {
                this.formService.languageFormGroup.setValue(language);
                this.formService.languagesArray.push(this.formService.cloneFormGroup(this.formService.languageFormGroup));
                this.formService.languageFormGroup.reset();
            });

            targetingCampaign.locations?.forEach(location => {
                this.formService.locationsFormGroup.setValue({
                    id: location.id,
                    name: location.name
                });
                this.formService.locationsArray.push(this.formService.cloneFormGroup(this.formService.locationsFormGroup));
                this.formService.locationsFormGroup.reset();
            });

            targetingCampaign.demographic_countries?.forEach(location => {
                this.formService.locationsFormGroup.setValue({
                    id: location,
                    name: location
                })
                this.formService.demographicCountriesArray.push(this.formService.cloneFormGroup(this.formService.locationsFormGroup));
                this.formService.locationsFormGroup.reset();
            });

            targetingCampaign.proximity_targets?.forEach(proximity => {
                this.formService.proximityFormGroup.setValue(proximity);
                this.formService.proximityFormArray.push(this.formService.cloneFormGroup(this.formService.proximityFormGroup));
                this.formService.proximityFormGroup.reset();
            });

            if (this.formService.proximityFormArray.length > 0) {
                const blob = this.generateCSV(this.formService.proximityFormArray.value);
                this.formService.targetingFormGroup.get('geoFenceFile')?.patchValue({ url: window.URL.createObjectURL(blob), name: 'locations.csv' });
            }

            targetingCampaign.interests?.forEach((interest) => {
                this.formService.interestsFormGroup.setValue({
                    id: interest,
                    name: interest
                })
                this.formService.interestsArray.push(this.formService.cloneFormGroup(this.formService.interestsFormGroup));
                this.formService.interestsFormGroup.reset();
            });

            targetingCampaign.whitelisted_keywords?.forEach(keyword => {
                this.formService.whiteListedKeywordsFormGroup.setValue({
                    id: keyword,
                    name: keyword
                });
                this.formService.whiteListedKeywordsArray.push(this.formService.cloneFormGroup(this.formService.whiteListedKeywordsFormGroup));
                this.formService.whiteListedKeywordsFormGroup.reset();
            });

            targetingCampaign.blacklisted_keywords?.forEach(keyword => {
                this.formService.blackListedKeywordsFormGroup.setValue({
                    id: keyword,
                    name: keyword
                });
                this.formService.blackListedKeywordsArray.push(this.formService.cloneFormGroup(this.formService.blackListedKeywordsFormGroup));
                this.formService.blackListedKeywordsFormGroup.reset();
            });

            targetingCampaign.whitelisted_domains?.forEach(keyword => {
                this.formService.whiteListedDomainsFormGroup.setValue({
                    id: keyword,
                    name: keyword
                });
                this.formService.whiteListedDomainsArray.push(this.formService.cloneFormGroup(this.formService.whiteListedDomainsFormGroup));
                this.formService.whiteListedDomainsFormGroup.reset();
            });

            targetingCampaign.blacklisted_domains?.forEach(domain => {
                this.formService.blackListedDomainsFormGroup.setValue({
                    id: domain,
                    name: domain
                });
                this.formService.blackListedDomainsArray.push(this.formService.cloneFormGroup(this.formService.blackListedDomainsFormGroup));
                this.formService.blackListedDomainsFormGroup.reset();
            });

            targetingCampaign.keywords?.forEach(item => {
                this.formService.targetingKeywordFormGroup.setValue({
                    keyword: item.keyword,
                    match_type_label: item.match_type_label,
                    active: item.active,
                    id: item.id,
                    match_type_id: item.match_type_id,
                    type_id: item.type_id,
                    type_label: item.type_label

                });
                this.formService.targetingFormArray.push(this.formService.cloneFormGroup(this.formService.targetingKeywordFormGroup));
                this.formService.targetingKeywordFormGroup.reset();
            });

            targetingCampaign.negative_keywords?.forEach(item => {
                this.formService.negativeKeywordFormGroup.setValue({
                    keyword: item.keyword,
                    match_type_label: item.match_type_label,
                    active: item.active,
                    id: item.id,
                    match_type_id: item.match_type_id,
                    type_id: item.type_id,
                    type_label: item.type_label
                });
                this.formService.negativeFormArray.push(this.formService.cloneFormGroup(this.formService.negativeKeywordFormGroup));
                this.formService.negativeKeywordFormGroup.reset();
            });

            targetingCampaign?.contract_ids?.forEach((id: number) => {
                this.formService.contractIdArray.push(new FormControl({ id }));
            })

            targetingCampaign?.geofence_ids?.forEach((id: number) => {
                this.formService.geofenceListArray.push(new FormControl({ id }));
            })

            targetingCampaign?.congressional_district_ids?.forEach((id: number) => {
                this.formService.congressionalDistrictListArray.push(new FormControl({ id }));
            })
        }

        this.formService.searchBudgetGroup.setValue({
            start_date: res?.campaign.start_date,
            end_date: res?.campaign.end_date,
            daily_budget: res?.campaign.daily_budget,
            cpc_bid: res?.campaign.cpc_bid || 0
        });

        const platform_id: number = res?.campaign.platform_id ?? 0;
        this.formService.platformIdControl.setValue(platform_id);
        const platform_label: string = res?.campaign.platform_label as string;
        switch (platform_label) {
            case PlatformTypeEnum.Programmatic:
                if (targetingCampaign?.geofencing_file_id) {
                    this.formService.targetingFormGroup.get('geofencing_file_id')?.setValue(targetingCampaign.geofencing_file_id);
                    this.formService.targetingFormGroup.get('selectedGeofenceOption')?.setValue(0);
                    this.subs.add(this.uvmService.getAnCreativeEffect(targetingCampaign?.geofencing_file_id).subscribe(data => {
                        if (data) {
                            this.formService.targetingFormGroup.get('geoFenceFile')?.patchValue({ url: window.URL.createObjectURL(data), name: 'locations.csv' });
                            this.getGeofenceTableData(data).then(obj => {
                                for (let i = 0; i < obj.length; i++) {
                                    this.formService.programmaticProximityFormGroup.patchValue({
                                        radius: obj[i]['Radius (meters)'],
                                        latitude: obj[i]['Latitude'],
                                        longitude: obj[i]['Longitude'],
                                        radius_unit_label: 'Meters'
                                    });
                                    this.formService.programmaticProximityFormArray.push(this.formService.cloneFormGroup(this.formService.programmaticProximityFormGroup));
                                    this.formService.programmaticProximityFormGroup.reset();
                                }
                            }).catch((error) => {
                                console.error('Failed to get geofence data:', error);
                            });
                        }
                    }));
                } else if ((targetingCampaign?.geofence_ids?.length ?? 0) > 0) this.formService.targetingFormGroup.get('selectedGeofenceOption')?.setValue(2);
                else this.formService.targetingFormGroup.get('selectedGeofenceOption')?.setValue(1);

                this.internalService.selectedTabSignal.set({
                    tabName: PlatformTypeEnum.Programmatic, id: platform_id,
                    hovered: false,
                    selected: true,
                });
                this.router.navigate(['/home/programmatic']);
                break;
            case PlatformTypeEnum.Search:
                this.internalService.selectedTabSignal.set({
                    tabName: PlatformTypeEnum.Search, id: platform_id,
                    hovered: false,
                    selected: true,
                });
                this.router.navigate(['/home/search']);
                break;
            case PlatformTypeEnum.YouTube:
                this.internalService.selectedTabSignal.set({
                    tabName: PlatformTypeEnum.YouTube, id: platform_id,
                    hovered: false,
                    selected: true,
                });
                this.router.navigate(['/home/youtube']);
                break;
        }

        this.formService.creativeFormGroup.get('canRemoveCreative')?.setValue(res?.campaign.flags.can_remove_creatives);
    }

    prefillRunningDaysGroups(days: string[]): void {
        days.forEach(day => {
            this.formService.runningDaysFormGroup.setValue({
                id: day,
                name: day
            })
            this.formService.runningDaysArray.push(this.formService.cloneFormGroup(this.formService.runningDaysFormGroup));
            this.formService.runningDaysFormGroup.reset();
        });
    }

    csvToArray(csvData: string): Record<string, string>[] {
        const lines = csvData.split('\n');
        const headers = lines[0].split(',');

        const result: Record<string, string>[] = [];

        for (let i = 1; i < lines.length; i++) {
            const obj: Record<string, string> = {};
            const currentLine = lines[i].split(',');

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }

            result.push(obj);
        }

        return result;
    }

    prefillAgeGroups(ageGroups: string[]): void {
        ageGroups.forEach(age_group => {
            this.formService.ageGroupsFormGroup.setValue({
                id: age_group,
                name: age_group
            })
            this.formService.ageGroupsArray.push(this.formService.cloneFormGroup(this.formService.ageGroupsFormGroup));
            this.formService.ageGroupsFormGroup.reset();
        });
    }

    prefillDeviceGroups(deviceGroups: string[]): void {
        deviceGroups.forEach(device => {
            this.formService.devicesFormGroup.setValue({
                id: device,
                name: device
            })
            this.formService.devicesGroupsArray.push(this.formService.cloneFormGroup(this.formService.devicesFormGroup));
            this.formService.devicesFormGroup.reset();
        });
    }

    prefillGenderGroups(genderGroups: string[]): void {
        genderGroups.forEach(gender => {
            this.formService.genderGroupsFormGroup.setValue({
                id: gender,
                name: gender
            })
            this.formService.genderGroupsArray.push(this.formService.cloneFormGroup(this.formService.genderGroupsFormGroup));
            this.formService.genderGroupsFormGroup.reset();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    convertBlobToPlainText(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const textContent = reader.result as string;
                resolve(textContent);
            };
            reader.onerror = () => reject(reader.error);
            reader.readAsText(blob);
        });
    }

    getGeofenceTableData(blob: Blob): Promise<{ [key: string]: string }[]> {
        return this.convertBlobToPlainText(blob).then((textContent) => {

            const rows = textContent.split('\n');
            const headers = rows[0].split(',');

            const data: { [key: string]: string }[] = [];

            for (let i = 1; i < rows.length; i++) {
                const values = rows[i].split(',');
                const obj: { [key: string]: string } = {};

                headers.forEach((header, index) => {
                    obj[header.trim()] = values[index] ? values[index].trim() : '';
                });

                data.push(obj);
            }
            return data;
        }).catch((error) => {
            console.error('Error reading Blob:', error);
            throw error;
        });
    }

    generateCSV(proximityTargets: ProximityTarget[]): Blob {
        const radiusUnit = proximityTargets[0].radius_unit_label;

        // Set the CSV headers
        const headers = ['Latitude', 'Longitude', `Radius (${radiusUnit})`];

        // Map the array to extract only the relevant data for each row
        const rows = proximityTargets.map((target) => {
            return [
                target.latitude,
                target.longitude,
                target.radius
            ];
        });

        // Create the CSV string
        let csvContent = headers.join(',') + '\n';
        rows.forEach((row: any[]) => {
            csvContent += row.join(',') + '\n';
        });

        // Create a Blob from the CSV string
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        return blob;
    }
}

