export interface CampaignNoMenClatureItem {
    id: number;
    name: string;
}

export interface CampaignNoMenClature {
    campaign_budget_distribution_id: CampaignNoMenClatureItem[];
    campaign_frequency_cap_interval_type_id: CampaignNoMenClatureItem[];
    campaign_objective_id: CampaignNoMenClatureItem[];
    campaign_platform_id: CampaignNoMenClatureItem[];
    campaign_status_id: CampaignNoMenClatureItem[];
    campaign_time: CampaignNoMenClatureItem[];
    campaign_timezone_id: CampaignNoMenClatureItem[];
    campaign_currency_id: CampaignNoMenClatureItem[];
    campaign_meta_id: CampaignNoMenClatureItem[];
    creative_status_id: CampaignNoMenClatureItem[];
    creative_type_id: CampaignNoMenClatureItem[];
    file_scope_id: CampaignNoMenClatureItem[];
    keyword_match_type_id: CampaignNoMenClatureItem[];
    keyword_type_id: CampaignNoMenClatureItem[];
    notification_status_id: CampaignNoMenClatureItem[];
    organisation_managed_mode_id: CampaignNoMenClatureItem[];
    payment_method_id: CampaignNoMenClatureItem[];
    proximity_target_radius_unit_id: CampaignNoMenClatureItem[];
    data_group_type_id: CampaignNoMenClatureItem[];
    language_id: CampaignNoMenClatureItem[];
    payment_platform_id: CampaignNoMenClatureItem[];
    payment_status_id: CampaignNoMenClatureItem[];
}

interface EligibleValues {
    age_groups: string[];
    devices: string[];
    genders: string[];
    interests: string[];
    running_days: string[];
    education: string[];
    keyword_language: string[];
    demographic_countries: string[];
    languages: CampaignNoMenClatureItem[];
    environments: string[];
    countries_with_zip_support: string[];
}

export interface NoMenClaturesMetaDataModel {
    eligible_values: EligibleValues;
}

export interface NoMenClaturesMetaDataStateModel {
    values: NoMenClaturesMetaDataModel | null;
    platform_id: number;
}

interface ZipCodeExamples {
    [country: string]: string;
}

export interface ZipCodeData {
    zip_code_examples: ZipCodeExamples;
}